/* eslint-disable no-eval */

import axios from "axios";
// import store from '@/store/index'

export default () => {
  return axios.create({
    // baseURL: 'http://3.93.160.146/'
    baseURL: "https://0g0qrhmsvg.execute-api.us-east-1.amazonaws.com/",
    headers: {
      'Accept': 'application/json', // eslint-disable-line
      'Content-Type': 'application/json' // eslint-disable-line
    },
    // headers: {
    //   Authorization: ''
    // }
  });
};
// baseURL: 'http://localhost:8081/',
// baseURL: 'https://jsonplaceholder.typicode.com/',
