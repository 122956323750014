<template>
  <v-row no-gutters class="text-center" justify="center">
    <v-col cols="12" md="6" sm="12" xs="12">
      <v-card
        raised
        shaped
        outlined
        class="d-flex flex-column px-10 py-3"
        id="top"
        ref="top"
      >
        <div class="error">{{ error }}</div>
        <!-- <h3>Welcome, please log in.</h3> -->
        <h2 class="primary--text">Logged Out successfully</h2>
        <router-link to="/login" class="primary--text">Login</router-link>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "LogoutSuccess",
  data: () => ({
    error: "",
  }),
  mounted: function () {},
};
</script>
