<template>
  <v-card height="70vh" class="d-flex flex-column px-8 py-4">
    <!-- <NavDrawer /> -->
    <h1 class="primary--text">Configure and deploy new automation</h1>

    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        color="accent"
        top style="margin-top:100px;"
      >
        <!-- {{ text }} -->
        <!-- <div style="width:10%;overflow:auto;overflow-y:scroll; text-overflow: ellipsis;"> -->
        <div style="width:10%;overflow:auto;overflow-y:scroll;">
          {{ text }}
        </div>

        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-dialog v-model="dialogLoadConf" max-width="600px">
            <v-card>
              <v-card-title class="text-h5 text--primary">Please enter the JSON conf object below:</v-card-title>
              <v-card-actions>
                <!-- <div class="d-flex flex-column" style="width=100px;"> -->
                <v-container width="100%" style="width=100%;">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                    <v-text-field
                      v-model="loadObj"
                      label="Conf JSON object"
                      outlined
                      required
                    ></v-text-field></v-col>
                  </v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="closeLoadConf">Cancel</v-btn>
                    <v-btn color="primary" text @click="loadConfConfirm()">Load Conf</v-btn>
                    <v-spacer></v-spacer>
                </v-container>
                <!-- </div> -->
              </v-card-actions>
            </v-card>
          </v-dialog>
    <div>
      <!-- <v-card height="650px" class="d-flex flex-column px-8 py-4" style="margin-top:10px"> -->
      <v-card height="600px" class="d-flex flex-column px-8 py-4" style="margin-top:10px;overflow:auto;overflow-y:scroll;">
        <h2 class="primary--text mb-3 font-weight-medium">
          Cloud Environment Details:
        </h2>

        <v-text-field
          label="Environment Name"
          v-model="envName"
          outlined>
        </v-text-field>
        <v-select
          :items="choices"
          label="New Repo?"
          outlined
          v-model="newRepo"
        ></v-select>
        <v-text-field
          v-if="newRepo == 'false'"
          label="UID"
          v-model="uid"
          outlined>
        </v-text-field>
        <v-text-field
          label="Client Name"
          v-model="clientName"
          outlined>
        </v-text-field>
        <v-text-field
          label="Client SID (three chars lowercase)"
          outlined
          v-model="clientSID">
        </v-text-field>
        <v-select
          :items="envs"
          label="Cloud Environment Type"
          outlined
          v-model="envType"
        ></v-select>
        <v-select
          :items="versions"
          label="SBO+FP Version"
          outlined
          v-model="sboVersion"
        ></v-select>

        <div class="d-flex justify-space-around"
          style="
            max-width: 50%;
            margin-left: 15px;
            margin-bottom: 25px;
          ">
          <v-row>
            <p>Servers:</p>
            <v-checkbox
                v-model="srvHANA"
                label="HANA"
                color="primary"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="srvRDP"
                label="SBO App/TS/RDP"
                color="primary"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="srvSvcLyr"
                label="Service Layer"
                color="primary"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="srvInt"
                label="Integration Framework"
                color="primary"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="srvWS"
                label="WS"
                color="primary"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="srvSQL"
                label="SQL"
                color="primary"
                hide-details
              ></v-checkbox>
          </v-row>
        </div>

        <div class="d-flex justify-space-around" v-if="srvHANA"
          style="
            max-width: 60%;
            margin-top: 10px;
            margin-left: 75px;
            margin-bottom: 10px;
          ">
          <v-row>
            <v-text-field
              label="HANA Instance Type"
              outlined
              v-model="hanaIns.instance_type">
            </v-text-field>
            <v-text-field
              label="HANA Root Volume Size"
              outlined
              v-model="hanaIns.volume_size">
            </v-text-field>
            <v-text-field
              label="HANA Cost Center"
              outlined
              v-model="hanaIns.cost_center">
            </v-text-field>
          </v-row>
        </div>
        <div class="d-flex justify-space-around" v-if="srvRDP"
          style="
            max-width: 60%;
            margin-left: 75px;
            margin-bottom: 10px;
          ">
          <v-row>
            <v-text-field
              label="RDP Instance Type"
              outlined
              v-model="rdpIns.instance_type">
            </v-text-field>
            <v-text-field
              label="RDP Root Volume Size"
              outlined
              v-model="rdpIns.volume_size">
            </v-text-field>
            <v-text-field
              label="RDP Cost Center"
              outlined
              v-model="rdpIns.cost_center">
            </v-text-field>
          </v-row>
        </div>
        <div class="d-flex justify-space-around" v-if="srvSvcLyr"
          style="
            max-width: 60%;
            margin-left: 75px;
            margin-bottom: 10px;
          ">
          <v-row>
            <v-text-field
              label="SRV Instance Type"
              outlined
              v-model="srvIns.instance_type">
            </v-text-field>
            <v-text-field
              label="SRV Root Volume Size"
              outlined
              v-model="srvIns.volume_size">
            </v-text-field>
            <v-text-field
              label="SRV Cost Center"
              outlined
              v-model="srvIns.cost_center">
            </v-text-field>
          </v-row>
        </div>
        <div class="d-flex justify-space-around" v-if="srvInt"
          style="
            max-width: 60%;
            margin-left: 75px;
            margin-bottom: 10px;
          ">
          <v-row>
            <v-text-field
              label="INT Instance Type"
              outlined
              v-model="intIns.instance_type">
            </v-text-field>
            <v-text-field
              label="INT Root Volume Size"
              outlined
              v-model="intIns.volume_size">
            </v-text-field>
            <v-text-field
              label="INT Cost Center"
              outlined
              v-model="intIns.cost_center">
            </v-text-field>
          </v-row>
        </div>
        <div class="d-flex justify-space-around" v-if="srvWS"
          style="
            max-width: 60%;
            margin-left: 75px;
            margin-bottom: 10px;
          ">
          <v-row>
            <v-text-field
              label="WS Instance Type"
              outlined
              v-model="wsIns.instance_type">
            </v-text-field>
            <v-text-field
              label="WS Root Volume Size"
              outlined
              v-model="wsIns.volume_size">
            </v-text-field>
            <v-text-field
              label="WS Cost Center"
              outlined
              v-model="wsIns.cost_center">
            </v-text-field>
          </v-row>
        </div>
        <div class="d-flex justify-space-around" v-if="srvSQL"
          style="
            max-width: 60%;
            margin-left: 75px;
            margin-bottom: 10px;
          ">
          <v-row>
            <v-text-field
              label="SQL Instance Type"
              outlined
              v-model="sqlIns.instance_type">
            </v-text-field>
            <v-text-field
              label="SQL Root Volume Size"
              outlined
              v-model="sqlIns.volume_size">
            </v-text-field>
            <v-text-field
              label="SQL Cost Center"
              outlined
              v-model="sqlIns.cost_center">
            </v-text-field>
          </v-row>
        </div>

        <v-select
          :items="hanaCompRoles"
          label="HANA-Component Role"
          outlined
          v-model="hanaCompRole"
        ></v-select>

        <v-text-field
          label="Cloud Provider"
          outlined
          v-model="sapEnvCloud">
        </v-text-field>
        <v-text-field
          label="Private CIDR"
          outlined
          v-model="privcidr">
        </v-text-field>
        <v-text-field
          label="Public CIDR"
          outlined
          v-model="pubcidr">
        </v-text-field>
        <v-select
          :items="timezones"
          label="Timezone"
          outlined
          v-model="timezone"
        ></v-select>
        <v-text-field
          label="HANA System Password"
          outlined
          v-model="hanaSystemPwd"
          :type="show1 ? 'text' : 'password'"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="show1 = !show1">
        </v-text-field>
        <v-text-field
          label="HANA Admin Password"
          outlined
          v-model="hanaAdminPwd"
          :type="show2 ? 'text' : 'password'"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="show2 = !show2">
        </v-text-field>
        <v-text-field
          label="B1SiteUser Password"
          outlined
          v-model="b1SiteUserPwd"
          :type="show3 ? 'text' : 'password'"
          :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="show3 = !show3">
        </v-text-field>
        <v-text-field
          label="HANA Shared LV Size (Int only in GB)"
          outlined
          v-model="hanaSharedLVSize">
        </v-text-field>
        <v-text-field
          label="HANA Data LV Size (Int only in GB)"
          outlined
          v-model="hanaDataLVSize">
        </v-text-field>
        <v-text-field
          label="HANA Log LV Size (Int only in GB)"
          outlined
          v-model="hanaLogLVSize">
        </v-text-field>
        <v-text-field
          label="HANA Backups LV Size (Int only in GB)"
          outlined
          v-model="hanaBackupsLVSize">
        </v-text-field>
        <v-text-field
          label="HANA Backup Logs LV Size (Int only in GB)"
          outlined
          v-model="hanaBackupLogsLVSize">
        </v-text-field>

        <v-text-field
          label="Service Unit"
          outlined
          v-model="serviceUnit">
        </v-text-field>

        <div class="d-flex justify-space-around"
          style="
            max-width: 60%;
            margin-left: 15px;
            margin-bottom: 25px;
        ">
          <v-row>
            <p>Download Installers?:</p>
            <v-checkbox
              v-model="hanaDLIns"
              label="HANA Installers"
              color="primary"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="hanaCompDLIns"
              label="HANA-Comp Installers"
              color="primary"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="compDLIns"
              label="Component Installers"
              color="primary"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="rdpDLIns"
              label="RDP Installers"
              color="primary"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="wsDLIns"
              label="WS Installers"
              color="primary"
              hide-details
            ></v-checkbox>
          </v-row>
        </div>

        <div class="d-flex justify-space-around"
          style="
            max-width: 50%;
            margin-left: 15px;
            margin-bottom: 25px;
        ">
          <v-row>
            <p>Other Params:</p>
            <v-checkbox
                v-model="hanaAddDns"
                label="HANA Add DNS"
                color="primary"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="compAddDns"
                label="Compoponent Add DNS"
                color="primary"
                hide-details
              ></v-checkbox>
          </v-row>
        </div>
          
        <v-select
          :items="compTypes"
          label="Component Type"
          outlined
          v-model="compType"
        ></v-select>
        <v-select
          :items="compRoles"
          label="Component Role"
          outlined
          v-model="compRole"
        ></v-select>
        <v-select
          :items="choices"
          label="Install Crystal?"
          outlined
          v-model="installCrystal"
        ></v-select>
        <v-select
          :items="crystalVs"
          label="Crystal Version"
          outlined
          v-model="crystalVersion"
        ></v-select>
        <v-text-field
          label="SAP OU Environment Name"
          outlined
          v-model="sapEnvName">
        </v-text-field>
        <v-text-field
          label="Master Sec OU Name"
          outlined
          v-model="masterSecName">
        </v-text-field>
        <v-text-field
          label="Short Sec OU Name"
          outlined
          v-model="shortSecName">
        </v-text-field>
        <v-text-field
          label="TSP Display Name"
          outlined
          v-model="tspDisplayName">
        </v-text-field>
        <v-text-field
          label="TSP Internal Name"
          outlined
          v-model="tspInternalName">
        </v-text-field>
        <v-text-field
          label="URL/Env (TSP) Title"
          outlined
          v-model="envtitle">
        </v-text-field>
        <v-select
          :items="partners"
          label="SAP Partner"
          outlined
          v-model="sapPartner"
        ></v-select>
        <v-select
          :items="choices"
          label="SSM Activation?"
          outlined
          v-model="ssmActivation"
        ></v-select>
        <v-text-field
          label="Hostname-Ext WS"
          outlined
          v-model="hostnameExtWS">
        </v-text-field>
        <v-text-field
          label="Hostname-Ext Int"
          outlined
          v-model="hostnameExtInt">
        </v-text-field>
        <v-text-field
          label="Windows Version"
          outlined
          v-model="winVersion">
        </v-text-field>
        
      </v-card>
    </div>
    <v-btn variant="flat" elevation="4" @click="launchEnv" dark color="deep-orange white--text" class="mt-2" :loading="loading">
      Launch environment!
    </v-btn>
    <v-btn variant="flat" elevation="4" @click="exportConf" dark color="primary" class="mt-2" :loading="loading">
      Save Config
    </v-btn>
    <v-btn variant="flat" elevation="4" @click="loadConf" dark color="accent" class="mt-2" :loading="loading">
      Load Config
    </v-btn>
  </v-card>
</template>

<script>
import TriggerPipelineService from '@/services/TriggerPipelineService';
import { mapState } from "vuex";
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "MainView",
  components: {},
  computed: {
    ...mapState(["isUserLoggedIn", "user", "client"]),
  },
  data: () => ({
    show1: false,
    show2: false,
    show3: false,
    snackbar: false,
    text: "",
    timeout: 10000,
    error: "",
    dialogLoadConf: false,
    //ENV DATA
    loading: false,
    envs: ['Dedicated (On-Premise) Hosted Environment', 'Dedicated Service Unit | Single-Tenant CCC Cloud Environment', 'Multi-tenant CCC / Shared Cloud Environment'],
    versions: ['SBO v10 2102', 'SBO v10 2108', 'SBO v10 2111', 'SBO v10 2202', 'SBO v10 2208'],
    timezones: ['us', 'cl', 'cr', 'mx', 'gt', 'es', 'hn', 'ni', 'pa', 'co', 'ar', 'bo'],
    compTypes: ['srv', 'ana', 'mob', 'js', 'wc'],
    compRoles: ['Analytics', 'AppFwk-BckupSvcs', 'AppFwk-BckupSvcs-Anal-Job', 'JobSvc', 'Mob-Anal', 'Mobile', 'SvcLyr', 'OpSvcLyrH', 'OpSvcLyr', 'SvcLyr-Anal-WebClient', 'SvcLyr-WebClient', 'WebClient'],
    hanaCompRoles: ['OpSld-Job-AppFwk-BckupSvcs-Anal', 'Analytics', 'AppFwk-BckupSvcs', 'AppFwk-BckupSvcs-Anal-Job', 'JobSvc', 'Mob-Anal', 'Mobile', 'SvcLyr', 'OpSvcLyrH', 'OpSvcLyr', 'SvcLyr-Anal-WebClient', 'SvcLyr-WebClient', 'WebClient'],
    crystalVs: ['2016', '2020'],
    partners: ['HCO', 'StarkCloud'],
    choices: ['true', 'false'],
    envName: "",
    clientName: "",
    envType: "",
    newType: "",
    sboVersion: "",
    newVersion: "",
    srvHANA: true,
    srvRDP: true,
    srvSvcLyr: false,
    srvInt: false,
    srvWS: false,
    srvSQL: false,
    appFwk: true,
    bckupSvcs: true,
    jobSvcs: true,
    analytics: true,
    clientSID: "",
    sapEnvCloud: "AWS",
    privcidr: "",
    pubcidr: "",
    timezone: "",
    hanaSystemPwd: "",
    hanaAdminPwd: "",
    b1SiteUserPwd: "",
    hanaSharedLVSize: "120",
    hanaDataLVSize: "100",
    hanaLogLVSize: "100",
    hanaBackupsLVSize: "120",
    hanaBackupLogsLVSize: "20",
    serviceUnit: "",
    hanaDLIns: true,
    hanaCompDLIns: true,
    compDLIns: true,
    rdpDLIns: true,
    wsDLIns: true,
    hanaAddDns: true,
    compAddDns: true,
    hanaCompRole: "",
    compType: "",
    compRole: "",
    installCrystal: 'true',
    crystalVersion: "",
    sapEnvName: "",
    masterSecName: "",
    shortSecName: "",
    tspDisplayName: "",
    tspInternalName: "",
    envtitle: "",
    sapPartner: "HCO",
    ssmActivation: "false",
    hostnameExtWS: "ws",
    hostnameExtInt: "int",
    winVersion: "2019",
    ghObj: {},
    newRepo: "true",
    sharedEnv: "",
    hanaDbCCC: "",
    rdpCCC: "",
    hanaIns: {
      instance_type: "r5a.2xlarge",
      volume_size: "50",
      cost_center: ""
    },
    rdpIns: {
      instance_type: "t3a.2xlarge",
      volume_size: "50",
      cost_center: ""
    },
    srvIns: {
      instance_type: "r5.large",
      volume_size: "50",
      cost_center: ""
    },
    intIns: {
      instance_type: "t3a.large",
      volume_size: "",
      cost_center: ""
    },
    wsIns: {
      instance_type: "t3a.large",
      volume_size: "",
      cost_center: ""
    },
    sqlIns: {
      instance_type: "t3a.xlarge",
      volume_size: "",
      cost_center: ""
    },
    export: null,
    loadObj: "",
    uid: ""
  }),
  methods: {
    async exportConf() {
      this.export = {
        envName: this.envName,
        clientName: this.clientName,
        envType: this.envType,
        newType: this.newType,
        sboVersion: this.sboVersion,
        newVersion: this.newVersion,
        srvHANA: this.srvHANA,
        srvRDP: this.srvRDP,
        srvSvcLyr: this.srvSvcLyr,
        srvInt: this.srvInt,
        srvWS: this.srvWS,
        srvSQL: this.srvSQL,
        appFwk: this.appFwk,
        bckupSvcs: this.bckupSvcs,
        jobSvcs: this.jobSvcs,
        analytics: this.analytics,
        clientSID: this.clientSID,
        sapEnvCloud: this.sapEnvCloud,
        privcidr: this.privcidr,
        pubcidr: this.pubcidr,
        timezone: this.timezone,
        hanaSystemPwd: this.hanaSystemPwd,
        hanaAdminPwd: this.hanaAdminPwd,
        b1SiteUserPwd: this.b1SiteUserPwd,
        hanaSharedLVSize: this.hanaSharedLVSize,
        hanaDataLVSize: this.hanaDataLVSize,
        hanaLogLVSize: this.hanaLogLVSize,
        hanaBackupsLVSize: this.hanaBackupsLVSize,
        hanaBackupLogsLVSize: this.hanaBackupLogsLVSize,
        serviceUnit: this.serviceUnit,
        hanaDLIns: this.hanaDLIns,
        hanaCompDLIns: this.hanaCompDLIns,
        compDLIns: this.compDLIns,
        rdpDLIns: this.rdpDLIns,
        wsDLIns: this.wsDLIns,
        hanaAddDns: this.hanaAddDns,
        compAddDns: this.compAddDns,
        hanaCompRole: this.hanaCompRole,
        compType: this.compType,
        compRole: this.compRole,
        installCrystal: this.installCrystal,
        crystalVersion: this.crystalVersion,
        sapEnvName: this.sapEnvName,
        masterSecName: this.masterSecName,
        shortSecName: this.shortSecName,
        tspDisplayName: this.tspDisplayName,
        tspInternalName: this.tspInternalName,
        envtitle: this.envtitle,
        sapPartner: this.sapPartner,
        ssmActivation: this.ssmActivation,
        hostnameExtWS: this.hostnameExtWS,
        hostnameExtInt: this.hostnameExtInt,
        winVersion: this.winVersion,
        ghObj: this.ghObj,
        newRepo: this.newRepo,
        sharedEnv: this.sharedEnv,
        hanaDbCCC: this.hanaDbCCC,
        rdpCCC: this.rdpCCC,
        hanaIns: this.hanaIns,
        rdpIns: this.rdpIns,
        srvIns: this.srvIns,
        intIns: this.intIns,
        wsIns: this.wsIns,
        sqlIns: this.sqlIns
      }
      this.text = `Copy JSON object below to your clipboard and save in a local file: ${JSON.stringify(this.export)}`
      this.snackbar = true
    },
    loadConf() {
      this.dialogLoadConf = true
    },
    closeLoadConf() {
      this.dialogLoadConf = false
      this.$nextTick(() => {
      })
    },
    async loadConfConfirm() {
      let tempObj = JSON.parse(this.loadObj)
      this.envName = tempObj.envName,
      this.clientName = tempObj.clientName,
      this.envType = tempObj.envType,
      this.newType = tempObj.newType,
      this.sboVersion = tempObj.sboVersion,
      this.newVersion = tempObj.newVersion,
      this.srvHANA = tempObj.srvHANA,
      this.srvRDP = tempObj.srvRDP,
      this.srvSvcLyr = tempObj.srvSvcLyr,
      this.srvInt = tempObj.srvInt,
      this.srvWS = tempObj.srvWS,
      this.srvSQL = tempObj.srvSQL,
      this.appFwk = tempObj.appFwk,
      this.bckupSvcs = tempObj.bckupSvcs,
      this.jobSvcs = tempObj.jobSvcs,
      this.analytics = tempObj.analytics,
      this.clientSID = tempObj.clientSID,
      this.sapEnvCloud = tempObj.sapEnvCloud,
      this.privcidr = tempObj.privcidr,
      this.pubcidr = tempObj.pubcidr,
      this.timezone = tempObj.timezone,
      this.hanaSystemPwd = tempObj.hanaSystemPwd,
      this.hanaAdminPwd = tempObj.hanaAdminPwd,
      this.b1SiteUserPwd = tempObj.b1SiteUserPwd,
      this.hanaSharedLVSize = tempObj.hanaSharedLVSize,
      this.hanaDataLVSize = tempObj.hanaDataLVSize,
      this.hanaLogLVSize = tempObj.hanaLogLVSize,
      this.hanaBackupsLVSize = tempObj.hanaBackupsLVSize,
      this.hanaBackupLogsLVSize = tempObj.hanaBackupLogsLVSize,
      this.serviceUnit = tempObj.serviceUnit,
      this.hanaDLIns = tempObj.hanaDLIns,
      this.hanaCompDLIns = tempObj.hanaCompDLIns,
      this.compDLIns = tempObj.compDLIns,
      this.rdpDLIns = tempObj.rdpDLIns,
      this.wsDLIns = tempObj.wsDLIns,
      this.hanaAddDns = tempObj.hanaAddDns,
      this.compAddDns = tempObj.compAddDns,
      this.hanaCompRole = tempObj.hanaCompRole,
      this.compType = tempObj.compType,
      this.compRole = tempObj.compRole,
      this.installCrystal = tempObj.installCrystal,
      this.crystalVersion = tempObj.crystalVersion,
      this.sapEnvName = tempObj.sapEnvName,
      this.masterSecName = tempObj.masterSecName,
      this.shortSecName = tempObj.shortSecName,
      this.tspDisplayName = tempObj.tspDisplayName,
      this.tspInternalName = tempObj.tspInternalName,
      this.envtitle = tempObj.envtitle,
      this.sapPartner = tempObj.sapPartner,
      this.ssmActivation = tempObj.ssmActivation,
      this.hostnameExtWS = tempObj.hostnameExtWS,
      this.hostnameExtInt = tempObj.hostnameExtInt,
      this.winVersion = tempObj.winVersion,
      this.ghObj = tempObj.ghObj,
      this.newRepo = tempObj.newRepo,
      this.sharedEnv = tempObj.sharedEnv,
      this.hanaDbCCC = tempObj.hanaDbCCC,
      this.rdpCCC = tempObj.rdpCCC,
      this.hanaIns = tempObj.hanaIns,
      this.rdpIns = tempObj.rdpIns,
      this.srvIns = tempObj.srvIns,
      this.intIns = tempObj.intIns,
      this.wsIns = tempObj.wsIns,
      this.sqlIns = tempObj.sqlIns
      this.dialogLoadConf = false
      // this.text = 'Conf successfully loaded'
      // this.snackbar = true
    },
    async prepVars() {
      // let newType = ""
      // let newVersion
      if (this.uid == "") {
        const id = uuidv4();
        this.uid = id
      }
      // console.log(id);
      if (this.envType.includes("Dedicated")) {
        this.newType = "DedicatedOP"
        this.sharedEnv = "false"
        this.hanaDbCCC = "false"
        this.rdpCCC = "false"
      } else if (this.envtype.includes("Single-Tenant")) {
        this.newType = "DedicatedSU"
        this.sharedEnv = "false"
        this.hanaDbCCC = "true"
        this.rdpCCC = "true"
      } else {
        this.newType = "Shared"
        this.sharedEnv = "true"
        this.hanaDbCCC = "true"
        this.rdpCCC = "true"
      }
      if (this.sboVersion.includes("2108")) {
        this.newVersion = 2108
      } else if (this.sboVersion.includes("2102")) {
        this.newVersion = 2102
      } else if (this.sboVersion.includes("2111")) {
        this.newVersion = 2202
      } else if (this.sboVersion.includes("2202")) {
        this.newVersion = 22021
      } else if (this.sboVersion.includes("2208")) {
        this.newVersion = 22082
      }

      if (!this.srvHANA) {
        this.hanaIns = ""
      }
      if (!this.srvRDP) {
        this.rdpIns = ""
      }
      if (!this.srvSvcLyr) {
        this.srvIns = ""
      }
      if (!this.srvInt) {
        this.intIns = ""
      }
      if (!this.srvWS) {
        this.wsIns = ""
      }
      if (!this.srvSQL) {
        this.sqlIns = ""
      }
    },
    async prepObj() {
      let obj = {
        repo: this.envName,
        uid: this.uid,
        // clientName: this.clientName,
        envType: this.newType,
        fp_version: this.newVersion,
        hana_comp_role: this.hanaCompRole,
        shared_env: this.sharedEnv,
        client_sid: this.clientSID,
        client_sid_capital: this.clientSID.toUpperCase(),
        sap_env_cloud: this.sapEnvCloud,
        privcidr: this.privcidr,
        pubcidr: this.pubcidr,
        timezone: this.timezone,
        hana_system_pwd: this.hanaSystemPwd,
        hana_admin_pwd: this.hanaAdminPwd,
        b1siteuser_pwd: this.b1SiteUserPwd,
        hana_shared_lv_size: this.hanaSharedLVSize,
        hana_data_lv_size: this.hanaDataLVSize,
        hana_log_lv_size: this.hanaLogLVSize,
        hana_backups_lv_size: this.hanaBackupsLVSize,
        hana_backup_logs_lv_size: this.hanaBackupLogsLVSize,
        service_unit: this.serviceUnit,
        hana_download_installers: this.hanaDLIns,
        hanacomp_download_installers: this.hanaCompDLIns,
        comp_download_installers: this.compDLIns,
        rdp_download_installers: this.compDLIns,
        ws_download_installers: this.compDLIns,
        hana_add_dns: this.hanaAddDns,
        comp_add_dns: this.compAddDns,
        hana_dbCCC: this.hanaDbCCC,
        rdpCCC: this.rdpCCC,
        comp_type: this.compType,
        comp_role: this.compRole,
        install_crystal: this.installCrystal,
        crystalversion: this.crystalVersion,
        sap_env_name: this.sapEnvName,
        master_sec_name: this.masterSecName,
        short_sec_name: this.shortSecName,
        tsp_display_name: this.tspDisplayName,
        tsp_internal_name: this.tspInternalName,
        envtitle: this.envtitle,
        sap_partner: this.sapPartner,
        ssm_activation: this.ssmActivation,
        hostname_ext_ws: this.hostnameExtWS,
        hostname_ext_int: this.hostnameExtInt,
        win_version: this.winVersion,
        hana_instance: JSON.stringify(this.hanaIns),
        rdp_instance: JSON.stringify(this.rdpIns),
        srv_instance: JSON.stringify(this.srvIns),
        int_instance: JSON.stringify(this.intIns),
        ws_instance: JSON.stringify(this.wsIns),
        sql_instance: JSON.stringify(this.sqlIns),
      }
      this.ghObj = obj
    },
    async launchEnv() {
      this.loading = true
      console.log("testing")
      // this.uid = ""

      await this.prepVars()
      await this.prepObj()
      
      const pipe = await TriggerPipelineService.triggerPipeline({
        client_full: `${this.envName}`,
        newRepo: `${this.newRepo}`,
        additional_args: JSON.stringify(this.ghObj)
      })
      if (pipe.status === 204) {
        await new Promise(resolve => setTimeout(resolve, 5000))
          .then(() => {
            this.loading = false;
            this.$router.push({
              name: 'track', 
              query: { 
                  envName: this.envName, 
                  uid: this.uid 
              }
            })
          })
      } else {
        console.log("not 204 response")
      }
    },
  },
  mounted() {
    // console.log(this.client);
  },
};
</script>
<style scoped>
html {
  overflow: hidden !important;
}

.cardcont {
  overflow: auto;
}
.on-hover {
  background-color: #376d67;
  box-shadow: 0px 6px 6px -3px rgb(0 0 0 / 20%),
    0px 6px 6px -3px rgb(0 0 0 / 14%), 0px 6px 6px -3px rgb(0 0 0 / 12%);
  cursor: pointer;
}
div.normal:not(.on-hover) {
  background-color: #00796b;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 3px 5px -1px rgb(0 0 0 / 14%), 0px 3px 5px -1px rgb(0 0 0 / 12%);
}
</style>
