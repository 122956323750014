<template>
  <v-app-bar app color="white" height="70" style="padding: 0 100px">
    <v-row>
      <v-col class="align-center">
        <a target="_blank" href="https://sap.starkcloud.com">
          <v-img
            alt="StarkCloud"
            class="shrink mt-1 hidden-sm-and-down"
            style="margin-left: -50px"
            contain
            min-width="150"
            src="http://www.starkcloud.com/wp-content/uploads/2023/03/cropped-logo-starkcloud.png"
            transition="scale-transition"
            width="150"
          />
        </a>
      </v-col>

      
      <v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer>
      <v-col class="align-self-center justify-self-center">
        <v-btn
          background-color="transparent"
          elevation="0"
          style="font-size: 1.3rem"
          class="primary--text"
          @click="$router.push('/')"
        >
          Cloud Environment Automations
        </v-btn>
      </v-col>
      
      <v-spacer></v-spacer><v-spacer></v-spacer>
      <v-btn
      v-if="isUserLoggedIn"
      color="white"
      outlined
      class="primary--text align-self-center"
      style="margin-left: 0px;"
      @click="$router.push('/track')"
      >
      <span>Track</span>
    </v-btn>
    <!-- <v-spacer></v-spacer> -->

      <p class="pa-1 mt-7 primary--text" style="padding-left: -10px"></p>
      <!-- spacer -->
      <v-col class="align-self-center justify-self-end">
        <v-btn
          v-if="isUserLoggedIn"
          color="primary"
          class="white--text"
          style="margin-left: 0px"
          @click="signOut()"
        >
          <span>Sign out</span>
        </v-btn>
        <v-btn
          v-if="!isUserLoggedIn"
          color="primary"
          class="white--text"
          to="/login"
        >
          <span>Login</span>
        </v-btn>
      </v-col>

      <p
        v-if="isUserLoggedIn && client"
        class="pa-1 mt-7 primary--text"
        style="padding-left: -10px"
      >
        Logged in as: <strong>{{ client.given_name }}</strong>
      </p>

      <!-- Logged in as: <strong>{{ client.given_name }}</strong> -->
    </v-row>
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "HeaderBar",
  data: () => ({
    error: "",
  }),
  computed: {
    ...mapState(["isUserLoggedIn", "token", "client"]),
  },
  methods: {
    check() {
      if (!this.isUserLoggedIn) {
        this.$router.push({
          name: "LogoutSuccess",
        });
      }
    },
    async signOut() {
      try {
        // this.$store.dispatch("setUser", "test");
        this.$store.dispatch("setToken", null);
        this.$store.dispatch("setLoggedIn", false);
      } catch (err) {
        // this.error = error.response.data.error
        this.error = err.response;
        console.log(this.error);
      }
      this.$router.push({
        name: "LogoutSuccess",
      });
    },
  },
};
</script>
