/* eslint-disable no-unused-vars */
import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../views/Main.vue";
import Track from "../views/Track.vue";

import Error from "../views/ErrorView.vue";
import LogoutSuccess from "../views/LogoutSuccess.vue";
import store from "./../store";
// Cognito services
import auth from "../services/auth";
import UserInfoApi from "../services/UserInfoApi";

Vue.use(VueRouter);

//ROUTES
const routes = [
  {
    path: "/",
    name: "main",
    component: Main,
    beforeEnter: requireAuth,
  },
  {
    path: "/track",
    name: "track",
    component: Track,
    beforeEnter: requireAuth,
  },
  {
    path: "/track/:id",
    name: "trackID",
    component: Track,
    // beforeEnter: requireAuth,
  },
  // Cognito Login routes and functions
  {
    path: "/login",
    beforeEnter(to, from, next) {
      auth.auth.getSession();
    },
  },
  {
    path: "/login/oauth2/code/cognito",
    beforeEnter(to, from, next) {
      var currUrl = window.location.href;

      // console.log(currUrl);
      auth.auth.parseCognitoWebResponse(currUrl);
      // next();
    },
  },
  {
    path: "/logout",
    name: "LogoutSuccess",
    component: LogoutSuccess,
    beforeEnter(to, from, next) {
      auth.logout();
      next();
    },
  },
  {
    path: "/error",
    name: "Error",
    component: Error,
  },
];

//FUNCTION
// Route guard for authentication
function requireAuth(to, from, next) {
  if (!auth.auth.isUserSignedIn()) {
    // UserInfoStore.setLoggedIn(false);
    store.dispatch("setLoggedIn", false);
    next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  } else {
    UserInfoApi.getUserInfo().then((response) => {
      // UserInfoStore.setLoggedIn(true);
      store.dispatch("setLoggedIn", true);
      store.dispatch("setClient", response);
      store.dispatch("setUserType", response["custom:usertype"]);
      // console.log(response['custom:custom:partnerou'])
      // UserInfoStore.setCognitoInfo(response);
      // store.dispatch('setCognitoInfo', response)
      next();
    });
  }
}

//SETUP ROUTER
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
