<!-- eslint-disable -->
<template>
  <div class="alert alert-danger">
    <h1>Error: {{ message }}</h1>
  </div>
</template>
<script>
export default {
  name: "ErrorView",
  data: () => ({
    message: "",
  }),
  mounted() {
    this.message = this.$route.query.message;
  },
};
</script>
