import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    userType: null,
    // Cognito vars
    client: null,
    isUserLoggedIn: false,
    cognitoInfo: null,
    loadingState: true,
    errorLoadingState: false,
    // Form Fields
    envLaunched: false,
    envDetails: {
      name: '',
      envType: '',
      servers: [],
      sboVersions: '',
      hanaComps: [],
      sboApps: [],
      url: ''
    }
  },
  mutations: {
    // COGNITO STATES
    setLoggedIn(state, newValue) {
      Vue.set(this.state, "isUserLoggedIn", newValue);
      if (!newValue) {
        Vue.set(this.state, "cognitoInfo", {});
      }
    },
    setClient(state, client) {
      Vue.set(this.state, "client", client);
    },
    setUserType(state, userType) {
      Vue.set(this.state, "userType", userType);
    },
    setUser(state, user) {
      // state.user = user
      Vue.set(this.state, "user", user);
    },
    setCognitoInfo(cognitoInfo) {
      Vue.set(this.state, "cognitoInfo", cognitoInfo);
    },
    // XXX FIELD ACTIONS
  },
  actions: {
    // COGNITO ACTIONS
    setLoggedIn({ commit }, newValue) {
      commit("setLoggedIn", newValue);
    },
    setClient({ commit }, client) {
      commit("setClient", client);
    },
    setUser({ commit }, user) {
      commit("setUser", user);
    },
    setUserType({ commit }, userType) {
      commit("setUserType", userType);
    },
    setCognitoInfo({ commit }, cognitoInfo) {
      commit("setCognitoInfo", cognitoInfo);
    },
    // XXX FIELD ACTIONS
  },
  modules: {},
  // getters: {
  //   getUser(state) => { this.state.user }
  // },
});
