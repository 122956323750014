<template>
  <v-card height="70vh" class="d-flex flex-column px-8 py-4">
    <h1 class="primary--text">Automation Tracker</h1>
    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        color="accent"
        top style="margin-top:100px;"
      >
        <!-- {{ text }} -->
        <!-- <div style="width:10%;overflow:auto;overflow-y:scroll; text-overflow: ellipsis;"> -->
        <div style="width:100%">
          {{ text }}
        </div>

        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    <div>
      <v-card height="550px" class="d-flex flex-column px-8 py-4" style="margin-top:10px">
        <h2 class="primary--text mb-3 font-weight-medium">
          Status & Progress:
        </h2>

        <v-container width="50%" style="width=50%;">
          <v-row>
            <v-text-field
              label="Environment Name"
              v-model="envName"
              outlined
              style="max-width:450px;">
            </v-text-field>
            <v-text-field
              label="Automation ID"
              v-model="autoID"
              outlined
              style="max-width:450px;"
              class="ml-2">
            </v-text-field>

            <v-btn variant="flat" style="width:300px" elevation="4" @click="loader = 'loading'; trackID()" dark color="deep-orange white--text" class="mt-2 ml-5" :loading="loading">
              Track this ID
            </v-btn>

            <!-- <h4 v-for="item in progress" :key="item" style="font-size:36px; font-weight:normal"> -->
              <!-- {{ item.name }} -->
            <!-- </h4> -->
          </v-row>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="status"
          :items-per-page="5"
          class="elevation-1"
        ></v-data-table>

      </v-card>
    </div>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import EnvAutoTableService from '@/services/EnvAutoTableService'

export default {
  name: "MainView",
  components: {},
  computed: {
    ...mapState(["isUserLoggedIn", "user", "client"]),
  },
  data: () => ({
    show1: false,
    snackbar: false,
    timeout: 2500,
    text: "",
    error: "",
    loading: false,
    //ENV DATA
    progress: [{
      name: 'Provisioning Cloud Environment Servers...'
    }],
    envName: "",
    autoID: null,
    headers: [
      {
        text: 'ID', align: 'start', sortable: false, value: 'id',
      },
      { text: 'Name', value: 'envName' },
      { text: 'HANA', value: 'hana' },
      { text: 'HANA Component', value: 'hanaComp' },
      { text: 'RDP', value: 'rdp' },
      { text: 'Component', value: 'component' },
      { text: 'WS', value: 'ws' },
      { text: 'INT', value: 'int' },
      { text: 'SQL', value: 'sql' },
    ],
    status: [],
    intervalId: null
  }),
  methods: {
    async trackID() {
      if (this.intervalId === null) {
        // await new Promise(resolve => setTimeout(resolve, 3000))
        this.intervalId = setInterval(this.trackID, 60000);
      }
      const l = this.loader
      this[l] = !this[l]

      const response = await EnvAutoTableService.getAutoInfo({
        id: `${this.autoID}`,
        envName: `${this.envName}`,
      })
      if (!response.data.error) {
        // this[l] = false
        // this.loader = null
        this.text = 'Updated'
        this.snackbar = true
        // this[l] = false
        // this.loader = null
        
        // this.status[0] = response.data
        this.status = []
        this.status.push(response.data)
      } else {
        // this[l] = false
        // this.loader = null
        this.text = `${response.data.error}`
        this.snackbar = true
        // this.snackbarError = true
        this.status = [
          {
            id: 'Pending initiation of status table or pending successful retrieval',
            envName: '',
            hana: '',
            hanaComp: '',
            rdp: '',
            component: '',
            int: '',
            ws: '',
            sql: ''
          }
        ]
      }
    },
    stopTracking() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    }
  },
  mounted() {
    // this.initiate()
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed to avoid memory leaks
    this.stopTracking();
  },
  created () {
    this.envName = this.$route.query.envName
    this.autoID = this.$route.query.uid
    // console.log('ID from query param:', this.id)
    // if (this.envName != "" && this.autoID != "") {
    //   this.trackID()
    // }
  }
};
</script>
<style scoped>
html {
  overflow: hidden !important;
}

.cardcont {
  overflow: auto;
}
.on-hover {
  background-color: #376d67;
  box-shadow: 0px 6px 6px -3px rgb(0 0 0 / 20%),
    0px 6px 6px -3px rgb(0 0 0 / 14%), 0px 6px 6px -3px rgb(0 0 0 / 12%);
  cursor: pointer;
}
div.normal:not(.on-hover) {
  background-color: #00796b;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 3px 5px -1px rgb(0 0 0 / 14%), 0px 3px 5px -1px rgb(0 0 0 / 12%);
}
</style>
