import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    // iconfont: "fa",
    iconfont: "mdiSvg",
    // iconfont: ["fa", "mdiSvg"],
  },
  theme: {
    themes: {
      light: {
        primary: "#023651",
        secondary: "#D22031",
        accent: "#368AC7",
      },
    },
  },
});
