import Api from '@/services/TriggerPipelineAPI'

export default {

  repoActions (inputs, additional_args) {
    const response = Api().post('/repos/StarkCloudLLC/Template-ServiceUnit-Ansible/actions/workflows/main.yml/dispatches', {
      ref: "main",
      inputs: inputs,
      additional_args: additional_args
    })
    return response
  },
  triggerPipeline (inputs) {
    // const response = Api().post(`/repos/StarkCloudLLC/${inputs.repo}/actions/workflows/main.yml/dispatches`, {
    const response = Api().post(`/repos/StarkCloudLLC/Template-ServiceUnit-Ansible/actions/workflows/main.yml/dispatches`, {
      ref: "main",
      inputs: inputs,
      // additional_args: additional_args
    })
    return response
  }
}
