<template>
  <v-app>
    <HeaderBar />

    <v-card class="d-flex flex-row">
      <!-- <NavDrawer style="margin-top: 82px" /> -->
      <v-main>
        <v-container fluid>
          <router-view />
        </v-container>
      </v-main>
    </v-card>

    <FooterBar />

    <!-- <v-main>
      <router-view />
    </v-main> -->
  </v-app>
</template>

<script>
import HeaderBar from "./components/HeaderBar";
import FooterBar from "./components/FooterBar";
// import NavDrawer from "./components/NavDrawer";

export default {
  name: "App",

  components: {
    HeaderBar,
    FooterBar,
    // NavDrawer,
  },
  data: () => ({
    //
  }),
};
</script>
