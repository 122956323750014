/* eslint-disable no-eval */

import axios from 'axios'
// import store from '@/store/index'

export default () => {
  return axios.create({
    baseURL: 'https://api.github.com/',
    auth: {
      username: 'anything',
      password: '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'
    },
    headers: {
      'Accept': 'application/vnd.github+json',
      'Content-Type': 'application/json',
      'X-GitHub-Api-Version': '2022-11-28'
    }
  })
}